import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["entries", "pagination", "spinner"]

  connect() {
    console.log("InfiniteScroll controller connected");
    this.loading = false;
    this.scrollHandler = this.scroll.bind(this);
    window.addEventListener('scroll', this.scrollHandler);
  }
  
  disconnect() {  
    window.removeEventListener('scroll', this.scrollHandler);
  }
  
  scroll() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (!next_page || this.loading) return;

    let body = document.body;
    let html = document.documentElement;
    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (window.pageYOffset > height - window.innerHeight - 100) {
      this.loadNextPage(next_page.href);
    }
  }

  loadNextPage(url) {
    this.loading = true;
    this.showSpinner();

    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.handleNewContent(data);
      },
      error: () => {
        console.error("Failed to load next page");
        this.loading = false;
        this.hideSpinner();
      }
    });
  }

  handleNewContent(data) {
    // Insert new content
    this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
    this.updatePagination(data.pagination);
    
    // Initialize new ads in the loaded content
    this.initializeNewAds();
    
    this.hideSpinner();
    this.loading = false;
  }

  initializeNewAds() {
    // Find all new ad containers that were just added
    const newAdContainers = this.entriesTarget.querySelectorAll('.ad-container:not([data-loaded])');
    
    newAdContainers.forEach(container => {
      // Mark as processed to avoid re-initialization
      container.setAttribute('data-loaded', 'true');
      
      // Initialize AdSense for this container if it exists and isn't loaded
      const adInsElement = container.querySelector('ins.adsbygoogle');
      if (adInsElement && !adInsElement.hasAttribute('data-adsbygoogle-status')) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
      
      // Ensure the Stimulus controller is connected
      if (!container.hasAttribute('data-controller')) {
        container.setAttribute('data-controller', 'adsense');
      }
    });
  }

  updatePagination(paginationHtml) {
    this.paginationTarget.innerHTML = paginationHtml;
    if (!this.paginationTarget.querySelector("a[rel='next']")) {
      console.log("Reached the last page");
    }
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.style.display = 'block';
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.style.display = 'none';
    }
  }
}