import './sidebar';
import './close_button';
import './dropdown';
import './thumbnails';
import './profiles';
import './image-preview';
import './submit-page';
import './clear-other-inputs';
import './disable-prompt';
import './is-pwa-installed';
import './dropdown-tools';
import './search';
import './pwa-button-visibility';
import './keyboardAwareForm';
import './scroll-to-bottom';
import './clear-message-input';
import './list-selection';
import './scroll-to-item';
import './auto_resize_textarea';
import './notifications-dropdown';
import './conditional-nav-display';
import './theme-switch';
import './profile_tabs_handler';
import './conditional-bottom-nav-display';
import './rooms-list-page-scroll';
import './back-button-updates';
import './ui-updates';
import './navigation';
import './clickableLinkElement';
import './scroll-manager';

$(document).ready(function() {
  let isStandalone = false;

  if (window.matchMedia('(display-mode: standalone)').matches) {
    isStandalone = true;
  }

  if (!isStandalone) {
    $(".empty-footer").css("min-height", "2.5rem");
  }
});

document.addEventListener('turbolinks:load', function() {
  handleImageLoad();
  handleTabClickForMobile();
});

function handleImageLoad() {
  const wrapper = document.querySelector(".link-show-page.image-link .image-link-layout .image-link-layout__top .desktop-image-media .image-wrapper");
  const img = wrapper.querySelector("img");

  if (img && img.complete) {
      wrapper.style.border = "none";
  } else {
      img.addEventListener("load", function () {
          wrapper.style.border = "none";
      });
  }
}

function handleTabClickForMobile() {
  if (window.matchMedia('(max-width: 1200px)').matches) {
      const tabs = document.querySelectorAll('.tab-link');

      tabs.forEach(tab => {
          tab.addEventListener('click', function(event) {
              event.preventDefault();
              const url = this.getAttribute('href');
              Turbolinks.visit(url, { action: 'replace' });
          });
      });
  }
}

